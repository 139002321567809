:root {
    --scrollbar-bg-color: transparent;
    --scrollbar-thumb-color: #616164;
    --scrollbar-thumb-hover-color: #A0A0A0;
}

.alwaysShowScrollbar ** {
    /* Firefox scrollbar Foreground, Background */
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-bg-color);
    scrollbar-width: thin;
}

.alwaysShowScrollbar *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.alwaysShowScrollbar *::-webkit-scrollbar-track {
    background: var(--scrollbar-bg-color);
}
.alwaysShowScrollbar *::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 5px;
}
.alwaysShowScrollbar *::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
}
